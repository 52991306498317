import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

export const coursesTabs = () => [
  {
    name: "notEnrolled",
    label: t("course.tabs.notEnrolled"),
    component: "CoursesNotEnrolled",
  },
  {
    name: "inProgress",
    label: t("course.tabs.inProgress"),
    component: "CoursesInProgress",
  },
  {
    name: "finished",
    label: t("course.tabs.finished"),
    component: "CoursesFinished",
  },
];

export const getActionsConf = (tab, course, pointToEnroll) => [
  {
    label:
      pointToEnroll.value > 0
        ? pointToEnroll.value + t("course.credit")
        : t("course.free"),
    redirect: {
      name: "courses.desc",
      params: { slug: course.slug },
    },
    condition: tab == "notEnrolled",
    showProgress: false,
  },
  {
    label: t("course.continue"),
    redirect: {
      name: "course",
      params: { id: course.enroll_id },
    },
    condition: tab == "progress",
    showProgress: true,
    progress: course.progress,
  },
  {
    label: t("course.get_certificate"),
    redirect: {
      name: "course.certificate",
      params: { slug: course.enroll_slug },
    },
    condition: tab == "finish",
    showProgress: true,
    progress: 1,
  },
];
