<template>
  <div class="default-shadow bg-white q-pa-md q-ma-sm default-rounded">
    <q-img :src="imageThumb" @error="imageFetchFailed = true" :ratio="16 / 9" />
    <div class="row" v-if="tab != 'finish'">
      <q-space />
      <q-badge
        rounded
        color="green"
        class="text-uppercase card-badge"
        :label="
          $t('course.card.win', {
            value: parseFloat(course.points_if_complete),
          })
        "
      />
    </div>

    <q-item-label
      lines="1"
      class="text-weight-bolder text-uppercase"
      :class="{ 'q-mt-md': tab == 'finish' }"
      >{{ course.name }}</q-item-label
    >

    <div
      style="font-size:8pt;"
      class="subtitle text-capitalize text-grey-8"
      v-html="
        $t('course.card.code', {
          value: course.slug,
        })
      "
    />
    <template
      v-for="(action, i) in getActionsConf(tab, course, pointToEnroll)"
      :key="i"
    >
      <div v-if="action.condition">
        <q-linear-progress
          size="25px"
          class="q-mt-xs"
          color="green"
          v-if="action.showProgress"
          :value="action.progress"
        />
        <q-btn
          :to="action.redirect"
          :label="action.label"
          color="dark"
          class="q-ma-sm default-rounded text-weight-regular full-width"
        /></div
    ></template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { isJson } from "@/shared/helpers/stringManipulations";
import { getActionsConf } from "../setup";
import { ref, computed } from "vue";

const UseAssents = createNamespacedHelpers("assents");

export default {
  name: "CourseItem",

  props: {
    course: {
      type: Object,
      required: true,
    },
    tab: {
      type: String,
      default: "notEnrolled",
    },
  },

  setup(props) {
    const pointToEnroll = ref(parseFloat(props.course.points_to_enroll)),
      imageFetchFailed = ref(false),
      { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]);

    const imageThumb = computed(() => {
      if (imageFetchFailed.value) return ImgNotFound;
      else if (isJson(props.course.images_videos))
        return JSON.parse(props.course.images_videos).capa_medium;
      else return props.course.images_videos;
    });

    return {
      pointToEnroll,
      imageThumb,
      imageFetchFailed,
      getActionsConf,
    };
  },
};
</script>

<style lang="scss">
.card-badge {
  top: 0;
  right: 1px;
  padding: 15px;
  transform: translateY(-50%);
}
</style>
